import axios from 'axios';
import React, { createRef, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Input, Row, Form } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import Loader from '../common/Loader';

const Customers = () => {
  const [loading, setLoading] = useState(true);


  const CustomTotal = ({ sizePerPage, totalSize, page, lastIndex }) => (
    <span>
      {(page - 1) * sizePerPage + 1} to {lastIndex > totalSize ? totalSize : lastIndex} of {totalSize} —{' '}
    </span>
  );

  const customerFormatter = uuid => (
    <Link to={`customer-detail/${uuid}`} className="font-weight-semi-bold">
      {uuid}
    </Link>
  );

  const columns = [
    {
      dataField: 'uuid',
      text: 'お客様番号',
      formatter: customerFormatter,
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true
    },
    {
      dataField: 'name',
      text: 'お客様名',
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true
    },
    {
      dataField: 'kana',
      text: 'フリガナ',
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true
    },
    {
      dataField: 'phone',
      text: '電話',
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true
    },
    {
      dataField: 'email',
      text: 'Email',
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true
    },
    {
      dataField: 'status',
      text: 'ご契約ステータス',
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true
    }
  ];

  let table = createRef();
  const handleNextPage = ({ page, onPageChange }) => () => {
    onPageChange(page + 1);
  };

  const handlePrevPage = ({ page, onPageChange }) => () => {
    onPageChange(page - 1);
  };

  const [customers, setCustomers] = useState([]);
  const [keyword, setKeyword] = useState('');

  const options = {
    custom: true,
    sizePerPage: 20,
    totalSize: customers.length
  };

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + `/api/customers?keyword=${keyword}`).then(response => {
      console.log(response);
      setCustomers(response.data);
      setLoading(false);
    }).catch((response) => {
      console.log(response);
    });
  }, [keyword]);

  return (
    <>
      <Card className="mt-3 mb-3">
        <FalconCardHeader title="お客様一覧" light={false}>
        <Form inline>
            <Input bsSize="sm" placeholder="Search.." value={keyword} onChange={({ target }) => setKeyword(target.value)}/>
            <Button className="ml-3" color="falcon-default" size="sm" href={process.env.REACT_APP_API_ENDPOINT + `/api/csv/customers?keyword=${keyword}`}>
                CSVダウンロード
              </Button>
        </Form>
        </FalconCardHeader>
        <CardBody className="p-0 border-top">
          {loading ? (
            <Loader />
          ) : (
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => {
                const lastIndex = paginationProps.page * paginationProps.sizePerPage;
                return (
                  <>
                    <div className="table-responsive">
                      <BootstrapTable
                        ref={table}
                        bootstrap4
                        keyField="uuid"
                        data={customers}
                        columns={columns}
                        bordered={false}
                        classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                        rowClasses="btn-reveal-trigger border-top border-200"
                        headerClasses="bg-200 text-900 border-y border-200"
                        {...paginationTableProps}
                      />
                    </div>
                    <Row noGutters className="px-1 py-3">
                      <Col className="pl-3 fs--1">
                        <CustomTotal {...paginationProps} lastIndex={lastIndex} />
                      </Col>
                      <Col xs="auto" className="pr-3">
                        <Button
                          color={paginationProps.page === 1 ? 'light' : 'primary'}
                          size="sm"
                          onClick={handlePrevPage(paginationProps)}
                          disabled={paginationProps.page === 1}
                          className="px-4"
                        >
                          Previous
                        </Button>
                        <Button
                          color={lastIndex >= paginationProps.totalSize ? 'light' : 'primary'}
                          size="sm"
                          onClick={handleNextPage(paginationProps)}
                          disabled={lastIndex >= paginationProps.totalSize}
                          className="px-4 ml-2"
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </>
                );
              }}
            </PaginationProvider>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default Customers;
