import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem
} from 'reactstrap';

const NavbarTopDropDownMenus = () => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <NavItem >
        <NavLink className="nav-link" to="/customers">
          お客様一覧
        </NavLink>
      </NavItem>

      <NavItem >
        <NavLink className="nav-link" to="/contracts">
          ご契約一覧
        </NavLink>
      </NavItem>

      <NavItem >
        <NavLink className="nav-link" to="/uploads">
          データアップロード
        </NavLink>
      </NavItem>

      <NavItem >
        <NavLink className="nav-link" to="/exports">
          データエクスポート
        </NavLink>
      </NavItem>

      <NavItem >
        <NavLink className="nav-link" to="/invoices">
          請求データ作成
        </NavLink>
      </NavItem>

      <NavItem >
        <NavLink className="nav-link" to="/notice">
          一括メール配信
        </NavLink>
      </NavItem>

      <NavItem onClick={() => setModal(true)}>
        <Link className="nav-link ml-3" to="#" >
          ログアウト
        </Link>
        <Modal isOpen={modal} >
          <ModalHeader>ログアウト確認</ModalHeader>
          <ModalBody>
            ログアウトしますか？
          </ModalBody>
          <ModalFooter>
            <Button tag={Link} to="/auth/logout" color="primary">
              ログアウト
            </Button>
            <Button onClick={() => setModal(false)}>
              キャンセル
            </Button>
          </ModalFooter>
        </Modal>
      </NavItem>
    </>
  );
};

export default NavbarTopDropDownMenus;
